/*main*/
@import '../public/assets/scss/main.scss';

header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 32px 0;

    .logo-container {
        .logo {
            background: url('../public/assets/images/logo-bi-1.svg') no-repeat center center;
            display: block;
            background-size: 100%;
            width: 254px;
            height: 88px;
        }
    }

    ul {
        display: flex;

        li {
            margin: 8px;
        }


    }

    .selectbox {
        .select {
            padding: 0;
            border: none;

            p {
                color: #333;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;

            }
        }

        .select:hover {
            p {
                color: #fff;
            }
        }

        .options {
            display: flex;
            flex-direction: column;
            top: 28px;
        }
    }

    .li-contacto {
        .active .selectbox .select p {
            color: #fff;
        }

        a:hover .selectbox .select p {
            color: #fff;
        }
    }

    .menu-toggle {
        display: none;
    }

    @media (max-width: 800px) {
        flex-direction: column;
        padding: 0;
        padding-top: 4px;
        position: fixed;
        background-color: white;
        top: 0;
        left: 0;
        right: 0;
        z-index: 102;

        .logo-container {
            display: flex;
            justify-content: space-between;
            width: 84%;

            .logo {
                width: 175px;
            }
        }

        #select-contacto {
            height: auto;

            .selectbox2 .select {
                justify-content: start;

                p {
                    margin-right: 16px;
                }
            }

            li.option {

                flex-direction: row !important;

                span {
                    margin: 0 !important;
                }
            }
        }


        .selectbox .options {
            position: inherit;
            z-index: inherit;
            left: inherit;

            .option:last-child {
                border-bottom: none;
                padding-bottom: 0 !important;
            }
        }

        div#select-contacto {
            width: 100% !important;
            padding: 0;
        }

        .li-contacto .selectbox {
            width: 100%;
            max-width: none;
        }

        li.option {
            padding: 32px !important;
        }

        .select {
            padding-left: 32px !important;
        }

        nav {
            width: 100%;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 500;
            top: 90px;
            position: fixed;
            overflow: auto;
            background-color: #FFF;


            ul {
                flex-direction: column;
                align-items: end;
                padding: 0;
                margin-top: 28px;

                li {
                    margin: 5px 0;
                    width: 100%;
                    border-bottom: 1px solid #ccc;
                    padding-bottom: 20px;
                    padding-top: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    a {
                        text-transform: uppercase;
                    }

                    .li-contacto {
                        width: 230px;
                    }

                    .selectbox .select {
                        text-transform: uppercase;

                        p {
                            font-weight: 400;
                            color: #333333 !important;
                        }

                        span {
                            margin-left: 0;
                            background-size: 100%;
                            width: 20px;
                        }
                    }

                    .bi-button-secondary {
                        display: block;
                        width: 100%;
                        border: none;
                        outline: none;
                        font-weight: 400;
                        width: 220px !important;

                        .selectbox .options .option {
                            background-color: inherit;
                            font-size: 16px;
                            padding: 0;
                        }

                    }

                    .bi-button-secondary.active {
                        background-color: inherit;
                        color: #333333;


                    }

                    .bi-button-secondary:hover {
                        background-color: inherit;
                        color: #333333;
                    }

                }

                li:last-child {
                    border-bottom: 1px solid #ccc;
                }

                .arrow-right {
                    background: url('../public/assets/images/icons/arrow-right-orange.svg') no-repeat center center;
                    display: block;
                    background-size: 100%;
                    width: 12px;
                    height: 19px;
                    margin-right: 28px;
                }
            }
        }

        .li-contacto {
            .selectbox {
                width: 100%;
            }
        }

        .menu-toggle {
            display: block;
        }

        .menu-hidden {
            display: none;
        }
    }

}

.home-container {

    .bar-top {
        width: 100%;
        height: 16px;
        background: radial-gradient(114.1% 292.49% at 5.1% -38.33%, #FF6200 0%, #DB2AB7 100%);
        margin-bottom: 64px;
    }

    .home-content {
        margin: 0 24px;
        display: flex;
        justify-content: space-between;
        padding-bottom: 80px;
        position: relative;

        .info {
            padding-right: 48px;

            .form-title {
                color: #1E1E1E;
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: 120%;
                margin-bottom: 24px;
            }

            .form-availability-wrapper {
                margin-bottom: 72px;

                .availability {
                    background-color: transparent;

                    input {
                        width: 100%;
                    }
                }

                .bi-button-primary {
                    display: flex;
                }

                .bi-button-tertiary {
                    display: none;
                }
            }

            .description {
                display: flex;
                flex-direction: column;

                .title {
                    margin-bottom: 30px;
                }

                .subtitle {
                    margin-bottom: 48px;
                }
            }

            .targets {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 24px;
                margin: 24px 0;

                .target {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-start;
                    width: 100%;
                    padding: 32px 16px 16px 16px;
                    border-radius: 16px;
                    border: 0.5px solid var(--Noche, #333);

                    .icon {
                        position: absolute;
                        top: -24px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 48px;
                        height: 48px;
                        border-radius: 50%;
                    }

                    .title,
                    .description {
                        margin-bottom: 8px;
                    }
                }
            }
        }
    }

    .buttons {
        display: flex;
        margin-top: 48px;
    }

    @media (max-width: 800px) {
        .home-content .info {
            padding-right: 0;
            margin-top: 24px;

            .form-content {
                display: none;
            }

            .description {
                .title {
                    font-size: 24px !important;
                    font-weight: 700 !important;
                    margin-bottom: 24px;
                }

                .subtitle {
                    font-size: 14px !important;
                    margin-bottom: 24px;
                }
            }

            span {
                font-size: 24px !important;
            }
        }

        .home-content {
            flex-direction: column;
        }

        .home-content #slider {
            margin-bottom: 345px;
            border-radius: 16px;
            max-width: calc(100vw - 48px);
            max-height: 524px;
        }

        .home-content .info .targets {
            position: absolute;
            z-index: 100;
            bottom: 10px;
            grid-template-columns: 1fr;
            gap: 44px;

            .target {
                a.bi-button-hyperlink {
                    font-size: 12px;
                }
            }
        }

        .bar-top {
            height: 2px;
            background: #989898;
            position: fixed;
        }
    }

}

.values-wrapper {
    margin-bottom: 24px;

    .box-container {

        .box-body,
        .box-head {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
            padding: 24px;
            background-color: #EEF1F6;
            border-radius: 8px;
        }

        .box-head {
            margin-bottom: 24px;

            .title {
                color: #757575;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                border-left: 3px solid #FF6200;
                padding: 0 24px;
                margin: 0;
                max-width: 175px;
            }
        }

        .box-body {

            .td,
            .td-orange {
                display: flex;
                align-items: start;
                border-left: 1px solid #000;
                padding-left: 24px;
                color: var(--text, #1E1E1E);
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;

                span {
                    color: var(--text, #1E1E1E);
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 120%;
                    margin-left: 8px;
                }
            }

            .td-orange {
                color: #FF6200;
            }

            .tr {
                margin-bottom: 24px;

                p {
                    color: var(--text, #1E1E1E);
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 120%;
                }
            }
        }
    }


}

.stepper-mobile {
    display: none;

    .title-reserve-component {
        margin-bottom: 18px;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
    }
}

@media (max-width: 800px) {
    .stepper-mobile {
        display: block;
    }
}


.bookings-container {
    display: flex;
    flex-direction: column;

    .content-mobile {
        display: none !important;
    }

    .content-desktop {
        display: flex;
    }

    .stepper-wrapper {
        margin-bottom: 32px;
    }

    .form-availability-wrapper {
        .bi-button-primary {
            display: flex;
        }

        .bi-button-tertiary {
            display: none;
        }
    }

    .bookings-wrapper {

        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        gap: 16px;
        grid-template-areas:
            "bookings-title bookings-image"
            "bookings-content content-right";
        margin-top: 20px;

        .content-right {
            grid-area: content-right;
        }

        .bookings-content {
            grid-area: bookings-content;
        }

        .bookings-image {
            grid-area: bookings-image;
        }

        .bookings-title {
            grid-area: bookings-title;
            margin-top: 64px;
        }
    }

    margin: 0 24px;

    .bookings-content,
    .bookings-title {
        display: flex;

        .info {
            position: relative;
            display: flex;
            justify-content: start;
            align-items: center;
            flex-direction: column;
            padding-right: 48px;

            .button-container {
                margin-bottom: 24px;
            }

            .button-container,
            .services,
            .div,
            input[type="text"] {
                width: 100%;
            }

            .title {
                color: #1E1E1E;
                font-size: 32px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                margin: 24px 0;
            }

            .subtitle {
                color: #1E1E1E;
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: 120%;
                margin: 24px 0;
            }

            .description {
                color: var(--text, #1E1E1E);
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                margin-bottom: 40px;

                strong {
                    font-weight: 700;
                }
            }

            .divider {
                border: .5px solid #FF6200;
            }

            .form {
                margin-top: 30px;
                width: 100%;

                .form-title {
                    color: #1E1E1E;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 120%;
                    margin-bottom: 24px;
                }

                button {
                    margin-top: 16px;
                    margin-bottom: 60px;
                }
            }

            .title-reserve {
                margin-top: 40px;
                width: 100%;

                p {
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 500;
                }
            }
        }
    }

    #slider {
        height: 34vw;
        max-width: calc(100vw - 48px);
        border-radius: 17px;
    }

    .content-right {

        .subtitle {
            color: #1E1E1E;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            margin: 40px 0 24px;
        }

        .description {
            color: #000;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            margin-bottom: 24px;
        }

        .services {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 24px 0;

            .service {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                .icon {
                    display: flex;
                    justify-content: center;
                    padding: 11px;
                    border-radius: 50%;
                    background-color: #EEF1F6;
                    margin-bottom: 16px;

                    svg {
                        width: 20px;
                        height: 20px;
                    }
                }

                .text {
                    color: #000;
                    font-family: 'Montserrat';
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }
        }
    }

    @media (max-width: 800px) {

        .content-mobile {
            display: grid !important;
        }

        .content-desktop {
            display: none;
        }

        .form-availability-wrapper {
            .bi-button-primary {
                display: none;
            }

            .bi-button-tertiary {
                display: flex;
            }
        }

        .stepper-wrapper {
            display: none;
        }

        .bookings-title .info {
            padding-right: 0;
        }

        p.description {
            font-size: 14px !important;
        }

        strong {
            font-weight: normal !important;
        }

        .bookings-wrapper {
            grid-template-columns: 1fr;
            grid-template-rows: auto;
            grid-template-areas:
                "bookings-title"
                "bookings-image"
                "bookings-content"
                "content-right";
            align-self: flex-start;
            margin-top: 50px;
        }

        .bookings-content,
        .bookings-image,
        .content-right {
            width: 100%;
        }

        .content-right {

            .div-content-right {
                padding: 0 !important;
            }

            .div p {
                font-size: 16px;
            }

            p.description {
                font-size: 14px;
            }

            .services {
                justify-content: start;
                margin: 0;
            }
        }

        .alert-warning {
            border-radius: 8px 0px 0px 8px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            padding: 8px 14px;
        }

        .alert-warning .symbol {
            width: 120px !important;
            transform: scale(0.75);
        }

        .bookings-content .info .form {
            margin-bottom: -58px;

            button {
                margin-top: 16px;
                width: 100%;
                margin-bottom: 42px;
            }
        }

        .alert .text p {
            font-size: 12px;
        }

        .bookings-content {
            display: block;

            .info {
                padding-right: 0;

                .div {
                    display: none;
                }

                .divider {
                    display: none;
                }

                .alert .text div p {
                    font-size: 12px;

                    strong {
                        font-weight: normal;
                    }
                }

                .alert-info .symbol {
                    width: 120px;
                    transform: scale(0.75);
                }

                .alert.alert-info {
                    border-radius: 8px 0px 0px 8px;
                    padding: 8px 14px;
                    justify-content: center;
                    margin: 24px 0px 0px !important;
                }

                .form .form-title {
                    font-size: 16px;
                }

                .title-reserve {
                    order: -1;
                    margin-top: 10px !important;

                    p {
                        font-size: 16px;
                    }
                }
            }
        }

        .services {
            flex-wrap: wrap;
            justify-content: space-around;

            .service {
                width: 33%;
                margin-bottom: 20px;
                text-align: center;

                .icon {
                    padding: 16px;
                    border-radius: 50%;
                    background-color: #E0E0E0;
                }

                .text {
                    font-size: 12px !important;
                    font-family: var(--font-lato) !important;
                }
            }
        }

        .availability {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            max-width: calc(100vw - 48px);
        }

        .form-availability-wrapper .availability .input-content {
            margin-bottom: 0;
        }

        .input-wrapper {
            flex: 1 1 auto;
            min-width: calc(33.333% - 10px);
            margin-bottom: 15px !important;
        }

        span.button-icon-reserve {
            position: absolute;
            margin-top: -248px;
            transform: scale(1.3);
            margin-left: 20px;
        }

        p.button-text-reserve {
            display: none;
        }

        p.title {
            display: none;
        }

        .bookings-title {
            margin-top: 0 !important;
        }

        .bookings-wrapper {
            margin-top: 0;
        }

        #slider {
            height: 74vw;
        }

    }
}

.bookings-confirm-container {
    display: flex;
    flex-direction: column;
    margin: 0 24px;

    .button-container-back {
        margin: 24px 0 40px;
    }

    .alert {
        margin-bottom: 40px;
    }

    .title {
        color: #1E1E1E;
        font-size: 33px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        margin-bottom: 24px;
    }

    .card-container {
        display: flex;
        justify-content: space-between;
        align-items: start;
        margin-bottom: 40px;

        .card {
            border-radius: 24px;
            border: 1px solid var(--Bordes, #757575);
            background: #FFF;
            padding: 24px;
            width: 100%;

            .desc-content {
                display: flex;
                margin-bottom: 24px;

                img {
                    width: 158px;
                    max-height: 147px;
                    border-radius: 8px;
                    overflow: hidden;
                    margin-right: 16px;
                }

                .title {
                    color: #1E1E1E;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 120%;
                    margin-bottom: 16px;
                }

                .characteristics {
                    margin-bottom: 16px;
                    display: flex;
                    align-items: center;
                    margin-bottom: 16px;

                    p {
                        color: #000;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 120%;
                    }

                    span {
                        margin-right: 8px;
                    }
                }
            }

            .title {
                color: #1E1E1E;
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: 120%;
                margin-bottom: 24px;
            }

            .bold {
                color: #1E1E1E;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                margin-bottom: 24px;
            }

            .light {
                color: #1E1E1E;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                margin-bottom: 24px;
            }

            .box-row {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }
    }

    .buttons {
        display: flex;
        align-items: center;
        justify-content: end;
        margin-bottom: 40px;
    }
}

.services-container {
    margin: 0 24px;
    min-height: calc(100vh - 372px);

    .title {
        color: var(--text, #1E1E1E);
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        margin-bottom: 16px;
    }

    .description {
        color: var(--text, #1E1E1E);
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        margin-bottom: 40px;
    }

    .categories-content {
        display: flex;
        margin-top: 70px;

        .categories {
            margin-left: 32px;

            .characteristics {
                display: flex;
                margin-bottom: 10px;

                span {
                    margin-right: 8px;
                }
            }

            .title {
                color: var(--text, #1E1E1E);
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: 120%;
                margin-bottom: 8px;

                span {
                    color: var(--Isidoro-Orange, #FF6200);
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 120%;
                    margin-left: 16px;
                }
            }

            .description {
                color: var(--text, #1E1E1E);
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
            }

            .icon {
                padding: 22px;
                border-radius: 50%;
                background-color: #EEF1F6;
                margin-right: 16px;
            }
        }
    }

    .galery-container {
        max-width: 384px;

        .galery {
            position: relative;
            width: 100%;
            height: 274px;
            border-radius: 24px;
            overflow: hidden;
            margin-bottom: 24px;

            img {
                width: 100%;
                height: 100%;
                transition: opacity 2s ease-in-out;
            }

            button {
                position: absolute;
                z-index: 10;
                bottom: 24px;
                right: 24px;

                span {
                    margin-right: 8px;
                }
            }
        }

        .images-mini {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 24px;

            .image {
                position: relative;
                min-width: 112px;
                max-width: 112px;
                min-height: 90px;
                max-height: 90px;
                border-radius: 24px;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    cursor: pointer;
                }
            }
        }
    }

    .button-container {
        margin: 40px 0;
        display: flex;
        flex-direction: row-reverse;

        a {
            padding: 8px 64px;
        }
    }

    .button-container-back {
        margin: 32px 0;
    }

    .map {
        width: 100%;
        height: 200px;
        border-radius: 16px;
        border: 1px solid;
        margin-top: 24px;
        overflow: hidden;

        iframe {
            width: 100%;
            height: 100%;
        }
    }

    .divider {
        display: none;
    }

    .categories.categories-mobile {
        display: none;
    }

    .mobile-services {
        display: none;
    }

    @media (max-width: 800px) {
        margin: 0;

        .mobile-services {
            display: block;
            position: fixed;
            background-color: white;
            width: 100%;
            z-index: 100;
            margin-top: -2px;
        }

        .title-content-component {
            font-size: 16px;
            font-weight: 700;
            text-align: center;
            color: #1E1E1E;
            font-style: normal;
            border-bottom: 1px solid #777;
            padding-bottom: 13px;
        }

        .availability-wrapper {
            display: none;
        }

        .content-component {
            display: flex;
            flex-direction: column;
        }

        .categories-content {
            flex-direction: column;
            margin-top: 20px;

            .categories {
                margin-left: 0;
                order: -1;
                margin: 0 24px;

                .title {
                    font-size: 14px;
                    font-weight: 700;
                }

                .category-text {
                    font-size: 14px;
                    font-weight: 400;
                }

                .icon {
                    padding: 12px;
                    transform: scale(0.8);
                }

                .characteristics {
                    margin-bottom: 0;
                }
            }
        }

        .button-container {
            display: none;
        }

        .button-container-back.desktop {
            display: none;
        }

        .title-services {
            margin-top: 54px;
        }

        .button-container-back {
            margin-top: -35px;

            .button-text-services {
                display: none;
            }

            .button-icon-services {
                position: absolute;
                margin-top: 82px;
                margin-left: 65px;
                transform: scale(1.3);
            }

        }

        .title {
            font-size: 18px;
            font-weight: 700;
            margin: 0 24px;
            margin-bottom: 24px;
        }

        .description {
            margin-bottom: 0;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            margin: 0 24px;
        }

        .galery-container {
            margin-bottom: 20px;
            margin: 0 24px;
            max-width: inherit;

            .galery {
                border-radius: 16px;
                height: 90vw;

                img {
                    object-fit: cover;
                }
            }

            .images-mini {
                display: none;
            }

            .map {
                height: 40vw;
                margin-bottom: 20px;
            }
        }

        .categories.categories-desktop {
            display: none;
        }

        .categories.categories-mobile {
            display: block;
        }

        .categories .category-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 24px;

            .icon-and-title {
                display: flex;
                align-items: center;
                margin-bottom: 16px;
            }

            .icon {
                padding: 22px;
                border-radius: 50%;
                background-color: #EEF1F6;
                margin-right: -24px;
                margin-left: -15px;
                transform: scale(0.65);
            }

            .category-title {
                margin-left: 0;
            }

            .characteristics-container {
                width: 100%;
                margin-top: -18px;

                p {
                    font-size: 14px;
                    font-weight: 400;
                }
            }
        }
    }
}

.facilities-container {
    margin: 0 24px;

    .title {
        color: var(--text, #1E1E1E);
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        margin-bottom: 16px;
    }

    .description {
        color: var(--text, #1E1E1E);
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        margin-bottom: 40px;
    }

    .facilities {
        display: flex;
        flex-direction: column;

        .galery-container {
            grid-area: galery-container;
        }

        .information {
            grid-area: information;
        }

        .title-information {
            grid-area: title-information;
            margin-left: 32px;
        }

        .facility {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: auto auto;
            gap: 0px 0px;
            grid-template-areas:
                "galery-container title-information"
                "galery-container information";
            margin-bottom: 70px;

            .galery-container {
                .galery {
                    position: relative;
                    min-width: 500px;
                    max-width: 500px;
                    height: 345px;
                    border-radius: 24px;
                    overflow: hidden;
                    margin-bottom: 40px;

                    img {
                        width: 100%;
                        height: 100%;
                        transition: opacity 2s ease-in-out;
                    }

                    button {
                        position: absolute;
                        z-index: 10;
                        bottom: 24px;
                        right: 24px;

                        span {
                            margin-right: 8px;
                        }
                    }
                }

                .images-mini {
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    gap: 24px;

                    .image {
                        position: relative;
                        min-width: 112px;
                        max-width: 112px;
                        min-height: 90px;
                        max-height: 90px;
                        border-radius: 24px;
                        overflow: hidden;

                        img {
                            width: 100%;
                            height: 100%;
                            cursor: pointer;
                        }
                    }
                }

                .button-container {
                    max-width: 500px;
                }
            }

            .information {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 100%;
                margin-left: 32px;

                .title {
                    color: var(--text, #1E1E1E);
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 120%;
                    margin-bottom: 24px;
                }

                .title-services {
                    color: var(--text, #1E1E1E);
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 120%;
                }

                .description {
                    color: var(--text, #1E1E1E);
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 120%;
                    margin-bottom: 8px;
                }

                .services {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin: 24px 0;

                    .service {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;

                        .icon {
                            display: flex;
                            justify-content: center;
                            padding: 11px;
                            border-radius: 50%;
                            background-color: #EEF1F6;
                            margin-bottom: 16px;

                            svg {
                                width: 20px;
                                height: 20px;
                            }
                        }

                        .text {
                            color: #000;
                            font-family: 'Montserrat';
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                        }
                    }
                }

                .characteristics {
                    display: flex;
                    margin-bottom: 18px;

                    span {
                        margin-right: 24px;
                    }

                    .characteristic {
                        color: #000;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 120%;
                    }
                }
            }
        }
    }

    .button-container-back {
        margin-bottom: 32px;
    }

    .buttons {
        display: flex;
        justify-content: end;
        margin: 40px 0 100px;
    }

    .mobile-services {
        display: none;
    }

    @media (max-width: 800px) {
        margin: 0;
        margin-top: -32px;

        .availability-wrapper {
            display: none;
        }

        .description {
            border-bottom: 1px solid #ff6102;
            padding-bottom: 30px;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            margin: 0 24px;
        }

        .facilities {
            margin: 0 24px;
            margin-top: 24px;
        }

        .title {
            font-size: 18px;
            font-weight: 700;
        }

        .buttons {
            display: none;
        }

        span {
            font-weight: 700 !important;
            font-size: 18px;
        }

        .facilities .facility {
            grid-template-columns: 1fr;
            grid-template-rows: auto auto auto;
            gap: 0px 0px;
            grid-template-areas:
                "title-information"
                "galery-container"
                "information";
            margin-bottom: 2px;

            .information .services {
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;

                .service {
                    flex-direction: row;
                    margin-top: -16px;

                    .text {
                        margin: 18px 12px 28px;
                        font-family: var(--font-lato);
                    }

                    .icon {
                        padding: 0;
                        border-radius: 0;
                        background-color: initial;
                    }
                }
            }

            .galery-container {
                .button-container {
                    display: none;
                }

                .galery {
                    min-width: inherit;
                    max-width: inherit;
                    max-height: 228px;
                    margin-bottom: 0;

                    img {
                        object-fit: cover;
                    }

                    border-radius: 16px;

                }
            }


            .information {
                margin-left: 0;

                .characteristics {
                    display: none;
                }

                .divider {
                    display: none;
                }

                .title-services {
                    font-size: 14px;
                    font-weight: 700;
                    margin-top: 30px;
                }
            }
        }

        .button-icon-facilities {
            position: absolute;
            margin-top: 80px;
            margin-left: 65px;
            transform: scale(1.3);
        }

        .title-facilities.mobile {
            display: block;
            text-align: center;
            margin: 0;
            border-bottom: 1px solid #777;
            padding-bottom: 13px;

            p {
                font-size: 16px !important;
                font-style: normal;
                color: #1E1E1E;
                font-weight: 700;
            }
        }

        .title-information {
            margin-left: 0 !important;
        }

        .button-container-back.desktop {
            display: none;
        }

        .mobile-services {
            display: block;
            position: fixed;
            background-color: white;
            width: 100%;
            z-index: 100;
            margin-top: -2px;
            top: 62px;
        }

        .subtitle-facilities {
            margin-top: 90px;
        }

        .title-facilities.desktop {
            display: none;
        }
    }

}


.facilities-select-container {
    margin: 0 24px;

    .title {
        color: var(--text, #1E1E1E);
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        margin-bottom: 16px;
    }

    .subtitle {
        color: var(--text, #1E1E1E);
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        margin-bottom: 16px;
    }

    .description {
        color: var(--text, #1E1E1E);
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        margin-bottom: 40px;
    }

    .services {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 24px 0 48px;
        width: 50%;

        .service {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            .icon {
                display: flex;
                justify-content: center;
                margin-bottom: 16px;
                width: 24px;
            }

            .text {
                color: #000;
                font-family: 'Montserrat';
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
    }

    .facilities {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        margin-bottom: 48px;

        .facility {
            display: flex;
            margin-bottom: 32px;

            .galery {
                position: relative;
                border: 1px solid;
                min-width: 366px;
                max-width: 366px;
                max-height: 366px;
                border-radius: 24px;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                }

                button {
                    position: absolute;
                    z-index: 10;
                    bottom: 24px;
                    right: 24px;

                    span {
                        margin-right: 8px;
                    }
                }
            }

            .information {
                margin-left: 32px;
                width: 100%;

                .title {
                    color: #1E1E1E;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 120%;
                    margin-bottom: 24px;
                }

                .characteristics {
                    display: flex;
                    margin-bottom: 18px;

                    span {
                        margin-right: 24px;
                    }

                    .characteristic {
                        color: #000;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 120%;
                    }
                }

                .periods {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    background-color: #EEF1F6;
                    padding: 16px;
                    border-radius: 8px;
                    margin-bottom: 16px;

                    .period-list {
                        display: flex;
                    }

                    .period-content {
                        display: flex;
                        flex-direction: column;

                        .title {
                            margin-bottom: 8px;
                        }

                        .title,
                        .price {
                            color: var(--text, #1E1E1E);
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 120%;
                        }

                        .period,
                        .total {
                            color: var(--text, #1E1E1E);
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 120%;
                        }
                    }
                }

                .radio-content {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    p {
                        color: #1E1E1E;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 120%;
                        /* 21.6px */
                    }
                }
            }

        }

        .button-container {
            margin: 32px 0 16px;
            display: flex;
            flex-direction: row-reverse;
        }
    }

    .button-container-back {
        margin-bottom: 32px;
    }

    .buttons {
        display: flex;
        justify-content: space-between;
        margin-bottom: 100px;
    }
}

.facilities-reserve-container {
    margin: 0 24px 40px;

    .facilities-reserve-content {
        display: flex;

        .facilities {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            position: relative;
            width: 100%;
            margin-bottom: 48px;

            .facility {
                display: flex;
                align-items: start;
                margin-bottom: 32px;

                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-template-rows: auto;
                gap: 22px;
                grid-auto-flow: row;
                grid-template-areas:
                    "information-data information-booking";

                #information-data {
                    grid-area: information-data;
                }

                #information-booking {
                    grid-area: information-booking;
                }


                .galery-container {
                    .galery {
                        position: relative;
                        min-width: 385px;
                        max-width: 385px;
                        height: 274px;
                        border-radius: 24px;
                        overflow: hidden;
                        margin-bottom: 24px;

                        img {
                            width: 100%;
                            height: 100%;
                            transition: opacity 2s ease-in-out;
                        }

                        button {
                            position: absolute;
                            z-index: 10;
                            bottom: 24px;
                            right: 24px;

                            span {
                                margin-right: 8px;
                            }
                        }
                    }

                    .images-mini {
                        display: grid;
                        grid-template-columns: repeat(3, 1fr);
                        gap: 24px;

                        .image {
                            position: relative;
                            min-width: 112px;
                            max-width: 112px;
                            min-height: 90px;
                            max-height: 90px;
                            border-radius: 24px;
                            overflow: hidden;

                            img {
                                width: 100%;
                                height: 100%;
                                cursor: pointer;
                            }
                        }
                    }

                    .map {
                        width: 100%;
                        height: 200px;
                        border-radius: 16px;
                        border: 1px solid;
                        margin-top: 24px;
                        overflow: hidden;

                        iframe {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }

                .information {
                    border-radius: 16px;
                    border: 1px solid #777;
                    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                    width: 100%;
                    padding: 32px;

                    .desc-content {
                        display: flex;

                        img {
                            width: 158px;
                            max-height: 147px;
                            border-radius: 8px;
                            overflow: hidden;
                            margin-right: 16px;
                        }

                        .title {
                            color: #1E1E1E;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 120%;
                            margin-bottom: 16px;
                        }

                        .characteristics {
                            margin-bottom: 16px;

                            p {
                                color: #000;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 120%;
                            }

                            span {
                                margin-right: 8px;
                            }
                        }
                    }

                    .title-mobile {
                        display: none;
                    }

                    .desc-content.content-mobile {
                        visibility: hidden;
                        position: absolute;
                    }

                    .desc-content.content-desktop {
                        visibility: visible;
                        position: inherit;
                    }

                    .divider {
                        margin-bottom: 24px;
                    }

                    .title,
                    .title-info {
                        color: #1E1E1E;
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 120%;
                        margin-bottom: 24px;
                    }

                    .title {
                        font-size: 18px;
                    }

                    .characteristics {
                        display: flex;
                        align-items: center;
                        margin-bottom: 18px;

                        span {
                            margin-right: 24px;
                        }

                        .characteristic {
                            color: #000;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 120%;
                        }
                    }

                    .bold {
                        color: var(--text, #1E1E1E);
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 120%;
                    }

                    .light {
                        color: #1E1E1E;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 120%;
                    }

                    .detail-group {
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 24px;

                        .total-text {
                            color: #1E1E1E;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 120%;
                        }

                        .value-total {
                            color: var(--text, #1E1E1E);
                            font-size: 24px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 120%;
                        }

                        .porcent-text {
                            color: #1E1E1E;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 120%;
                            margin-right: 30px;
                        }

                        .value-porcent {
                            color: var(--text, #1E1E1E);
                            font-size: 24px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 120%;
                        }
                    }
                }

            }

            .button-container {
                margin: 32px 0 16px;
                display: flex;
                flex-direction: row-reverse;
            }
        }

        #button-goToReserve {
            position: absolute;
            left: 0px;
            bottom: -36px;
            width: calc(50% - 8px);
        }
    }

    .button-container-back {
        margin: 24px 0 32px;
    }

    .button {
        display: flex;
        justify-content: end;
    }

    .form-reserve-wrapper .input-wrapper input::placeholder {
        visibility: hidden;
    }

    @media (max-width: 800px) {

        .stepper-wrapper {
            display: none;
        }

        strong {
            font-weight: normal;
        }

        span.button-icon-content {
            position: absolute;
            margin-top: -294px;
            transform: scale(1.3);
            margin-left: 20px;
        }

        p.button-text-content {
            display: none;
        }

        span.symbol {
            width: 155px !important;
            transform: scale(0.75);
        }

        .alert .text div p {
            font-size: 12px;
        }

        .alert .text {
            margin-left: inherit;
        }

        .form-reserve-wrapper .input-wrapper {
            margin-bottom: 16px;

            input {
                font-size: 14px;
            }
        }

        .facilities-reserve-content .facilities {
            margin-bottom: 0;
        }

        .facilities-reserve-content .facilities .facility {
            flex-direction: column;
            margin-bottom: 0;

            grid-template-columns: 1fr;
            grid-template-rows: 1fr 1fr;
            gap: 0px 0px;
            grid-template-areas:
                "information-booking"
                "information-data";

            #information-data {
                padding: 0;
            }

            #information-data {
                box-shadow: none;
                border: none;
            }

            .information {
                padding: 16px;
                border: 1px solid var(--Bordes, #757575);
                box-shadow: inherit;

                .title-info {
                    font-size: 16px;
                    margin-top: 24px;
                }
            }

        }

        .form-reserve-wrapper .input-wrapper input::placeholder {
            visibility: visible !important;
        }

        .availability-wrapper {
            display: none;
        }

        .form-reserve-wrapper .group-form {
            display: block;
        }

        .form-reserve-wrapper .group-form .input-wrapper {
            margin-left: 0;
        }

        .facilities-reserve-container {
            display: none;
        }

        .alert {
            border-radius: 8px 0px 0px 8px;
            margin: 0px 0px 32px;
            padding: 8px 10px;
            justify-content: center;
            align-items: center;
            gap: 10px;
        }

        .button-container-back {
            position: absolute;
        }

        .desc-content {

            .desc-content-right {
                padding-top: 0;
            }

            .desc-content-left {
                flex: 0 0 50%;
            }

            img {
                width: 95% !important;
                max-height: inherit !important;
                height: 38vw;
                object-fit: cover;
            }
        }

        .desc-content.content-mobile {
            visibility: visible !important;
            position: inherit !important;
        }

        .desc-content.content-desktop {
            visibility: hidden !important;
            position: absolute !important;
        }

        .title-mobile {
            display: block !important;

            .title {
                font-size: 16px !important;
                font-weight: 700 !important;
            }

            span {
                font-size: 16px;
                font-weight: 700 !important;
            }
        }

        .divider {
            display: none;
        }

        .content-price {
            margin-top: 16px;

            p.bold {
                font-size: 16px !important;
            }

            .detail-group {
                margin-bottom: 16px !important;

                p.light {
                    font-size: 14px !important;
                    font-weight: 400 !important;
                }

                p.bold {
                    font-size: 14px !important;
                }
            }

        }

    }

}

.prices-container {
    margin: 0 24px;
    width: 100%;

    .title {
        color: var(--text, #1E1E1E);
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        margin-bottom: 24px;
    }

    .description {
        color: var(--text, #1E1E1E);
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        margin-bottom: 24px;
    }

    .subtitle {
        color: var(--text, #1E1E1E);
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        margin-bottom: 24px;
    }

    .targets {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 64px;

        .target {
            display: flex;
            flex-direction: column;
            max-width: 300px;
            width: 100%;

            .icon {
                color: #FF6102;
                font-size: 48px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
            }

            .text {
                color: #000;
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: 120%;
                margin-top: 8px;
            }
        }
    }

    .companie {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 72px 0;
    }

    .button-container {
        margin: 40px 0;
        display: flex;
        flex-direction: row-reverse;

        button {
            padding: 8px 64px;
        }
    }

    .button-container-back {
        margin: 32px 0;
    }

    .values-wrapper-mobile {
        display: none;
    }

    .values-wrapper {
        display: block;
    }

    .title-container-back {
        display: none;
    }

    @media (max-width: 800px) {

        margin: 0;
        display: flex;
        flex-direction: column;

        .availability-wrapper {
            display: none;
        }

        .button-container-back {
            margin-top: -35px;
        }

        .title {
            font-size: 18px;
            font-weight: 700;
            margin: 0 24px;
        }

        .description {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            margin: 0 24px;
            margin-top: 24px;
        }

        .subtitle {
            font-size: 14px;
            margin-bottom: 0;
            margin: 0 24px;
            margin-top: 24px;
        }

        .targets {
            overflow-x: auto;
            scroll-snap-type: x mandatory;
            -webkit-overflow-scrolling: touch;
            scrollbar-width: none;
            justify-content: flex-start;
            flex-wrap: nowrap;
            margin: 0 24px;
            margin-top: 24px;
            align-items: stretch;
        }

        .targets::-webkit-scrollbar {
            display: none;
        }

        .target {
            flex: 0 0 auto;
            scroll-snap-align: start;
            margin-right: 24px;
            border: 1.5px solid #757575;
            margin-bottom: 25px;
            padding: 18px;
            border-radius: 14px;
        }

        .companie {
            flex-direction: column;
            padding: 0 64px;
            margin: 0;
            margin-top: 24px;

            img {
                margin-bottom: 50px;
                width: 100%;
            }
        }

        .button-container {
            display: none;
        }

        .values-wrapper {
            display: none;
        }

        .values-wrapper-mobile {
            display: block;
            margin-bottom: 24px;
            margin: 0 24px;

            .title-wrapper-mobile {
                font-weight: 700;
                font-size: 16px;
            }

            .date-wrapper-mobile {
                font-weight: 400;
                font-size: 12px;
            }

            .wrapper {
                border-radius: 10px;
                overflow: hidden;

                .content-wrapper {
                    padding: 16px !important;
                    font-size: 14px;
                    padding-top: 0 !important;

                    div {
                        margin-bottom: 16px;
                    }

                    div:last-child {
                        margin-bottom: 0;
                    }

                    .divider {
                        border: .5px solid #FF6200;
                    }
                }

            }

        }

        .title-container-back {
            display: block;
            text-align: center;
            color: #1E1E1E;
            font-size: 18px;
            font-style: normal;
            margin-bottom: 18px;
        }

        .button-text-values {
            display: none;
        }

        .button-icon-values {
            position: absolute;
            margin-top: 84px;
            margin-left: 65px;
            transform: scale(1.3);
        }

        div#title-prices {
            margin-top: 24px;
        }

        .button-container-back.desktop {
            display: none;
        }

        .title-services {
            margin-top: 54px;
            ;
        }

        .mobile-services {
            display: block;
            position: fixed;
            background-color: white;
            width: 100%;
            z-index: 100;
            margin-top: -2px;
        }

        .title-container-back.mobile {
            border-bottom: 1px solid #777;
            padding-bottom: 13px;
            margin: 0;
        }

        p.title-container-back.desktop {
            display: none;
        }

        .title-prices-component {
            margin-top: 54px;
        }

    }
}

footer {
    padding: 32px;
    background-color: #333;
    color: #fff;
    width: 100vw;

    .footer-container {
        display: flex;
        justify-content: space-between;
        max-width: 1200px;
        width: 100%;
        margin: 0 auto;

        .title {
            color: #FFF;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-bottom: 16px;
        }

        p {
            color: #FFF;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            margin-bottom: 6px;
        }
    }

    .logo-container {
        .logo {
            background: url('../public/assets/images/logo-bi-1.svg') no-repeat center center;
            display: block;
            background-size: 100%;
            width: 254px;
            height: 88px;
            border-radius: 100px;
        }
    }

    // Para pantallas más grandes que 800px
    @media (min-width: 801px) {
        .footer-container {
            align-items: center;
        }
    }

    // Para pantallas de 800px o menos
    @media (max-width: 800px) {
        padding-bottom: 86px;
        padding: 0 32px;

        .footer-container {
            flex-direction: column;
            margin-bottom: 70px;
            padding-bottom: 14px;
        }

        .logo-container .logo {
            background: url('../public/assets/images/logo-bi-mobile.svg') no-repeat left center;
            background-size: 100%;
            margin-bottom: 30px;
        }

        .footer-container p {
            text-align: left;
        }

        .footer-container div p:last-child {
            padding-bottom: 14px;
        }

        .footer-container div p:first-child {
            margin-bottom: 8px;
        }

        .logo-container {
            order: 1;
            margin-top: -18px;

            .logo {
                width: 184px;
                height: 64px;
            }
        }

        .item.contact {
            order: 2;
        }

        .item.locations {
            order: 3;
        }

        .item.links {
            order: 4;
        }

        p.title {
            font-size: 14px !important;
        }
    }
}


.story-container {
    margin: 0 24px;
    width: 100%;

    .title {
        color: var(--text, #1E1E1E);
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        margin-bottom: 16px;
    }

    .description {
        color: var(--text, #1E1E1E);
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        margin-bottom: 16px;
    }

    .categories-content {
        display: flex;
        margin-top: 70px;

        .categories {
            margin-right: 55px;

            .title {
                color: var(--text, #1E1E1E);
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: 120%;
                margin-bottom: 8px;
            }

            .description {
                color: var(--text, #1E1E1E);
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
            }

            .icon {
                padding: 22px;
                border-radius: 50%;
                background-color: #EEF1F6;
                margin-right: 16px;
            }
        }
    }

    .galery-container {
        max-width: 520px;

        .galery {
            position: relative;
            width: 100%;
            height: 400px;
            border-radius: 24px;
            overflow: hidden;
            margin-bottom: 24px;

            img {
                height: 100%;
                transition: opacity 2s ease-in-out;
            }

            button {
                position: absolute;
                z-index: 10;
                bottom: 24px;
                right: 24px;

                span {
                    margin-right: 8px;
                }
            }
        }

        .images-mini {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-auto-rows: minmax(0, 1fr);
            gap: 24px;
        }

        .images-mini .image {
            position: relative;
            min-width: 112px;
            max-width: 112px;
            min-height: 90px;
            max-height: 90px;
            border-radius: 24px;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                cursor: pointer;
            }
        }

        .images-mini .image:nth-child(n + 13) img {
            display: none;
        }
    }

    .button-container {
        margin: 90px 0;
        display: flex;
        flex-direction: row-reverse;

        a {
            padding: 8px 64px;
        }
    }

    .button-container-back {
        margin-bottom: 32px;
    }

    #divider-content {
        display: none;
    }

    .title-content {
        display: none;
    }

    .category-div {
        margin-bottom: 45px;
    }

    @media (max-width: 800px) {
        margin: 0;

        .story-content {
            margin: 0 24px;
        }

        strong {
            font-weight: normal;
        }

        .categories-content {
            flex-direction: column;
            margin-top: 0;
            margin: 0 24px;
        }

        .button-container {
            display: none;
        }

        .category-div {
            margin-bottom: 0px;
        }

        .availability-wrapper {
            display: none;
        }

        #divider-content {
            display: block;
            margin-bottom: 24px;
        }

        .title-content {
            display: block;
            text-align: center;
            color: #1E1E1E;
            font-size: 18px;
            font-style: normal;
            margin-bottom: 18px;
        }

        .title {
            font-size: 18px;
            font-weight: 700;
            margin-top: 54px;
        }

        .description {
            font-size: 14px !important;
        }

        .icon {
            transform: scale(0.6);
            margin-right: 0 !important;
        }

        .button-container-back {
            margin-bottom: 0;
        }

        .categories div {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            align-items: center !important;
        }

        .categories .title {
            text-align: center;
            font-size: 16px !important;
        }

        .categories-content .categories {
            margin-right: 0px;
        }

        .galery-container {
            .galery {
                border-radius: 17px;
            }

            .images-mini .image {
                display: none;
            }
        }

        .button-container-back.desktop {
            display: none;
        }

    }
}

.mobile-services {
    display: none;

    @media (max-width: 800px) {
        display: block;
        position: fixed;
        background-color: white;
        width: 100%;
        z-index: 100;
        margin-top: -2px;

        .title-content.mobile {
            border-bottom: 1px solid #777;
            padding-bottom: 13px;
            margin: 0;
            text-align: center;
        }

        .button-icon-story {
            position: absolute;
            margin-top: 20px;
            margin-left: 65px;
            transform: scale(1.3);
        }
    }

}

.button-container-back {
    margin: 24px;
    width: 100%;

    .contact-us-content {
        display: flex;
        justify-content: space-between;
        align-items: start;
        margin-top: 40px;
    }

    .box:first-child {
        margin-right: 36px;
    }

    .box {
        width: 100%;

        .title {
            color: #1E1E1E;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            margin-bottom: 24px;
        }

        .light {
            color: #000;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-bottom: 24px;
        }

        .orange {
            color: #FF6200;
        }

        .map {
            height: 330px;

            iframe {
                border-radius: 16px;
                height: 100%;
            }
        }

        .input-wrapper {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-bottom: 32px;

            label {
                color: #1E1E1E;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                margin-bottom: 8px;
            }

            input {
                border-radius: 8px;
                border: 1px solid #777;
                padding: 16px;

                color: var(--text, #1E1E1E);
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
            }

            textarea {
                resize: none;
                border-radius: 8px;
                height: 210px;
                padding: 16px;
                color: var(--text, #1E1E1E);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
            }

        }

        .form button {
            min-width: 70%;
        }

        #divider-title {
            display: none;
        }

        .divider {
            margin: 24px 0;
        }

        .input-wrapper input::placeholder {
            visibility: hidden;
        }

        .input-wrapper textarea::placeholder {
            visibility: hidden;
        }

    }

    .mobile-services {
        display: none;
    }

    @media (max-width: 800px) {
        margin: 0;

        .contact-us-content {
            flex-direction: column;
            margin-top: 0;
        }

        .form {
            margin: 0 24px;
            margin-top: 54px;

            button {
                margin-bottom: 16px;
                margin-top: 2px;
            }
        }

        .box {
            .map {
                height: 170px;
                margin: 0 24px;

                iframe {
                    width: 100%;
                }
            }

            .light {
                margin: 0 24px;
                margin-bottom: 10px;
            }

            .light:last-child {
                margin-bottom: 50px;

            }

            .light.orange {
                color: var(--text, #1E1E1E);
                font-weight: 700;
                margin-bottom: 10px;
                margin-top: 14px;
            }

            .title {
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                margin: 0 24px;
                margin-bottom: 16px;
            }

            #title-contact {
                border-top: 1px solid #FF6200;
                margin-top: 55px;
                padding-top: 20px;
            }

            #title-map {
                border-top: 1px solid #FF6200;
                padding-top: 16px;
            }

            #light-map {
                position: absolute;
                top: 950px;
            }

            .divider {
                top: 24px;
                position: relative;
            }

            #divider-contact {
                display: none;
            }

            .form button {
                min-width: 100%;
            }

        }

        .box .input-wrapper input {
            font-family: Lato;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
        }

        .box .input-wrapper {
            margin-bottom: 16px;

            label {
                display: none;
            }
        }

        .button-icon-contact {
            position: absolute;
            margin-top: 18px;
            margin-left: 65px;
            transform: scale(1.3);
        }

        .input-wrapper input::placeholder {
            visibility: visible !important;
        }

        .input-wrapper textarea::placeholder {
            visibility: visible !important;
        }

        .button-container-back.desktop {
            display: none;
        }

        .mobile-services {
            display: block;
            position: fixed;
            background-color: white;
            width: 100%;
            z-index: 100;
            margin-top: -2px;
        }

        .title.mobile {
            border-bottom: 1px solid #777;
            padding-bottom: 13px;
            margin: 0;
            text-align: center;
        }

        .title.desktop {
            display: none;
        }
    }

}

.contact-buttons {
    position: fixed;
    z-index: 100;
    bottom: 40%;
    right: 20px;
    display: flex;
    flex-direction: column;
    align-items: end;
    height: 156px;
    justify-content: space-between;
}

.error-container,
.message-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 970px;
    margin: 0 auto;
    min-height: calc(100vh - 332px);

    .title {
        color: #1E1E1E;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        text-align: center;
        margin: 30px 0 16px;
    }

    .subtitle {
        color: #1E1E1E;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        text-align: center;
        margin-bottom: 24px;

        a {
            font-size: 24px;
        }
    }

    .buttons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;

        a:first-child {
            margin-right: 16px;
        }
    }

}

.button-reserve-fixed {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 202;
    padding: 16px;
    background-color: white;

    a {
        width: 100%;
    }

    display: none;


}

@media (max-width: 800px) {
    .button-reserve-fixed {
        display: flex;
        flex-direction: column;
        gap: 16px;
        border: 1px solid white;
    }

    p.button-text {
        display: none !important;
    }

    div#divider-message {
        margin-top: -24px;
    }

    span.button-icon-message {
        position: absolute;
        margin-bottom: -33px;
        margin-left: 65px;
        transform: scale(1.3);
    }

    .message-container,
    .error-container {
        padding: 24px;
        margin-top: 40px;

        p.title {
            font-size: 18px !important;
            font-weight: 700 !important;
            margin-bottom: 16px;
        }

        .subtitle {
            font-size: 18px !important;
        }

        a.bi-button-hyperlink {
            font-size: 18px !important;
        }

        .buttons {
            margin-top: 48px;
        }

        a.bi-button-primary {
            padding: 8px 136px;
        }

        .buttons a:first-child {
            margin-right: 0;
        }
    }

    .error-container .buttons,
    .message-container .buttons {
        flex-direction: column;

        .bi-button-secondary {
            margin-bottom: 24px;
        }
    }



}

.overflow-hidden {
    overflow: hidden !important;

    .button-reserve-fixed {
        display: none;
    }
}